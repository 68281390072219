/*** App styles ***/
html { width: 100%; height: 100%; }
body { width: 100%; height: 100%; color: black; font-size: 18px; line-height: 1.2; font-family: Consolas, 'Courier New', monospace; background: #f5f5f5; }
body.no-scroll { overflow: hidden; }

#root { display: flex; width: 100%; height: 100%; }

#app { flex: 1 1 auto; }
#app.center-content { display: flex; justify-content: center; align-items: center; }

/*** Animations ***/
@keyframes spin {
	0% {
		transform:rotate(0deg)
	}
	to {
		transform:rotate(1turn)
	}
}
.spin { animation:spin 2s infinite linear; }
